import React, { useEffect, useMemo, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Theme, useMediaQuery } from '@mui/material';
import { Loading } from '@shared/components/loading';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { KYCDialogDialog } from '@shared/components/new-design/kyc-dialog/kyc-dialog';
import { VerificationDialogDialog } from '@shared/components/new-design/verification-dialog/verification-dialog';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { ErrorKeysEnum, HttpErrorResponse } from '@shared/models/error/http-error-response';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';
import { reaction } from 'mobx';
import { Document, Page } from 'react-pdf';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { CertificatesViewModel } from './certficates.vm';

import { styles } from './certficates.styles';

export interface CertificatesProps extends AppWithStyles<typeof styles> {}

const PersonalInfoComponent: React.FC<CertificatesProps> = appObserver(({ classes }) => {
  const $vm = useMemo(() => new CertificatesViewModel(), []);

  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  const navigateToSettings = () => {
    navigate(ROUTES.mobilePrivate.settings.root);
  };

  const { pathname } = useLocation();

  const [numPages, setNumPages] = useState<number>(1);
  const [isOpenVerificationDialog, setIsOpenVerificationDialog] = useState<boolean>(false);
  const [isOpenKYCDialog, setIsOpenKYCDialog] = useState<boolean>(false);

  useEffect(() => {
    reaction(
      () => $vm.currency,
      () => {
        downloadLimitsQuery.refetch();
      },
    );
  }, []);

  const downloadLimitsQuery = useQuery(
    ['download-certificate'],
    () => $vm.downloadUserCertificate(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      onError: (e: HttpErrorResponse | any) => {
        switch (true) {
          case e.hasError(ErrorKeysEnum.User_Not_Verified):
            setIsOpenVerificationDialog(true);
            break;
          default:
            $vm.showMessage(e.findFirstErrorMessage());
            break;
        }
      },
    },
  );

  const onCloseVerificationDialog = (result: boolean) => {
    if (result) {
      if (isMobile) {
        navigate(ROUTES.verification, { state: { redirect: pathname } });
      } else {
        setIsOpenKYCDialog(true);
      }
    }
    setIsOpenVerificationDialog(false);
  };

  const onCloseKYCDialog = () => {
    setIsOpenKYCDialog(false);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };

  const handleExport = () => {
    const link = document.createElement('a');
    link.href = $vm.certificateLink;
    link.setAttribute('download', 'Certificate of ownership.pdf');
    document.body.appendChild(link);
    link.click();
  };

  const getScreenWidth = () => {
    if (window.screen.width < 500) {
      return window.screen.width - 50;
    } else {
      return 490;
    }
  };

  let content = <></>;

  if (downloadLimitsQuery.isFetching) {
    return (
      <div className={classNames(classes.root, classes.invisibleContent, 'invisible-content')}>
        <Loading size={70} />
      </div>
    );
  } else if (Boolean($vm.certificateData)) {
    content = (
      <div className={classes.certificateBlock}>
        <Document
          className={classes.pdfDocument}
          file={$vm.certificateData}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {new Array(numPages)
            .fill(0)
            .map((v, index) => index + 1)
            .map((page) => {
              return (
                <Page width={getScreenWidth()} className={classes.pdfPage} pageNumber={page} />
              );
            })}
        </Document>
      </div>
    );
  } else if (downloadLimitsQuery.isError) {
    content = (
      <div className={classes.certificateBlock}>
        <div className={classes.placeholder}>{t`Certificate of ownership not available `}</div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.certificatesBlock}>
        <div className={classes.certificates}>
          <div className={classes.titleBlock}>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToSettings} />
            </div>
            <div className={classes.title}>{t`Certificate of Ownership`}</div>
            <div
              className={classNames(classes.exportButton, {
                [classes.invisible]: downloadLimitsQuery.isFetching || downloadLimitsQuery.isError,
              })}
              onClick={handleExport}
            ></div>
          </div>
          {content}
        </div>
      </div>
      <VerificationDialogDialog
        isOpen={isOpenVerificationDialog}
        onClose={onCloseVerificationDialog}
      />
      <KYCDialogDialog isOpen={isOpenKYCDialog} onClose={onCloseKYCDialog} />
    </div>
  );
});

export default appWithStyles(styles)(PersonalInfoComponent);
