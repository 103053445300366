import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { Currency } from '@shared/models/wallets/currency';
import { INotificationService } from '@shared/types/notification-service';
import { IRatesVM } from '@shared/types/rates-vm';
import { IUsersService } from '@shared/types/users-service';

export class CertificatesViewModel {
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private notificationsService = appInject<INotificationService>(DI_TOKENS.notificationService);
  private ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);
  private _certificate = {
    data: null as string | null,
    link: '',
  };

  constructor() {}

  get currency() {
    return this.ratesVM.currency;
  }

  get certificateData() {
    return this._certificate.data;
  }

  get certificateLink() {
    return this._certificate.link;
  }

  showMessage(text: string) {
    return this.notificationsService.showError(text);
  }

  downloadUserCertificate = async () => {
    this._certificate.data = await this.usersService.getMyInvestmentCertificate(
      Currency.GSC,
      this.currency,
    );
    const dataView = new DataView(this._certificate.data as any);
    const blob = new Blob([dataView], { type: 'application/pdf' });
    this._certificate.link = URL.createObjectURL(blob);
  };
}
