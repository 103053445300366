import React, { FC, useEffect, useMemo, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import ConfirmOrder from '@modules/new-private/orders/purchase-and-sell/confirm-order/confirm-order';
import PurchaseAndSellForm from '@modules/new-private/orders/purchase-and-sell/purchase-and-sell-form/purchase-and-sell-form';
import { IPurchaseAndSellForm } from '@modules/new-private/orders/purchase-and-sell/purchase-and-sell-form/purchase-and-sell-form.validator';
import { SellFiatTokenViewModel } from '@modules/new-private/orders/purchase-and-sell/sell-fiat-token.vm';
import { Theme, useMediaQuery } from '@mui/material';
import { Loading } from '@shared/components/loading';
import { KYCDialogDialog } from '@shared/components/new-design/kyc-dialog/kyc-dialog';
import { VerificationDialogDialog } from '@shared/components/new-design/verification-dialog/verification-dialog';
import { useParams, useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { HttpErrorResponse } from '@shared/models/error/http-error-response';
import { CreateOrderModel } from '@shared/models/orders/create-model';
import { SmartCoinOrderType } from '@shared/models/smart-coin-order-status/smart-coin-order-type';
import { Currency } from '@shared/models/wallets/currency';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';
// eslint-disable-next-line import/order
import { useMutation, useQuery } from 'react-query';

// eslint-disable-next-line import/order
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line import/order
import { DocumentDialog } from '../document-dialog';

// eslint-disable-next-line import/namespace,import/default

import { PurchaseViewModel } from '../purchase.vm';
import { Screen } from '../screens';
import { SellViewModel } from '../sell.vm';

import { styles } from './purchase-and-sell-base.styles';

export interface PurchaseAndSellBaseProps extends AppWithStyles<typeof styles> {}

const PurchaseAndSellBaseComponent: FC<PurchaseAndSellBaseProps> = appObserver(({ classes }) => {
  const { smartCoinName, orderType } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isOpenVerificationDialog, setIsOpenVerificationDialog] = useState<boolean>(false);
  const [isOpenDocumentDialog, setIsOpenDocumentDialog] = useState<boolean>(false);
  const [isOpenKYCDialog, setIsOpenKYCDialog] = useState<boolean>(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  const getViewModelByOrderType = (orderType: SmartCoinOrderType) => {
    const data = {
      smartCoinName: smartCoinName as string,
      navigateFn: navigate,
      setIsOpenVerificationDialog: setIsOpenVerificationDialog,
    };
    switch (orderType) {
      case SmartCoinOrderType.PURCHASE:
        return new PurchaseViewModel(data);
      case SmartCoinOrderType.SELL:
        return new SellViewModel(data);
      case SmartCoinOrderType.SELL_FIAT_TOKEN:
        return new SellFiatTokenViewModel(data);
      default:
        return new SellViewModel(data);
    }
  };

  const $vm = useMemo(
    () => getViewModelByOrderType(orderType as SmartCoinOrderType),
    [smartCoinName, orderType],
  );

  useEffect(() => {
    if (orderType === SmartCoinOrderType.SELL_FIAT_TOKEN) {
      $vm.setActiveCurrency(smartCoinName as string);
    }
  }, []);

  const downloadWalletsQuery = useQuery(
    ['download-wallets-list', smartCoinName, orderType],
    () => $vm.downloadWallets(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      onError: (e: HttpErrorResponse) => $vm.handleDownloadWalletsError(e),
    },
  );

  const fetchDocumentQuery = useQuery(['fetch-document'], () => $vm.fetchDocument(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const downloadCardsQuery = useQuery(
    ['download-cards-list', smartCoinName, orderType],
    () => $vm.downloadCards(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      onError: (e: HttpErrorResponse) => $vm.handleDownloadCardsError(e),
    },
  );

  const fetchMaintenanceModeStatusQuery = useQuery(
    ['fetch-maintenance-mode-status'],
    () => $vm.fetchMaintenanceModeStatus(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  const downloadLimitsQuery = useQuery(
    ['download-limits', smartCoinName, orderType],
    () => $vm.downloadLimits(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      onSuccess: () => $vm.handleDownloadLimitsSuccess(),
      onError: (e: HttpErrorResponse) => $vm.handleDownloadLimitsError(e),
    },
  );

  const downloadRatesQuery = useQuery(
    ['download-rates', smartCoinName, orderType, $vm.activeCurrency],
    () => $vm.downloadRates($vm.activeCurrency as Currency),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      onSuccess: () => $vm.handleDownloadRatesSuccess(),
      onError: (e: HttpErrorResponse) => $vm.handleDownloadRatesError(e),
    },
  );

  const createSmartCoinOrderMutation = useMutation(
    (data: {
      purchaseFormData: IPurchaseAndSellForm;
      smartCoinName: string;
      fiatCurrency: string;
    }) => $vm.createSmartCoinOrder(data.purchaseFormData, data.smartCoinName, data.fiatCurrency),
    {
      onSuccess: (order: CreateOrderModel | void) => $vm.handleSmartCoinOrderSuccess(order),
      onError: (error: HttpErrorResponse) => $vm.handleSmartCoinOrderError(error),
    },
  );

  const confirmSmartCoinOrderMutation = useMutation(
    (data: { orderId: string }) => $vm.confirmSmartCoinOrder(data.orderId),
    {
      onSuccess: (data) => {
        if (!data.id) {
          downloadRatesQuery.refetch();
        }
      },
    },
  );

  if (
    downloadCardsQuery.isLoading ||
    downloadLimitsQuery.isLoading ||
    downloadWalletsQuery.isLoading ||
    fetchDocumentQuery.isLoading ||
    createSmartCoinOrderMutation.isLoading ||
    fetchMaintenanceModeStatusQuery.isLoading ||
    !$vm.wallets ||
    !$vm.cards ||
    !$vm.rate
  ) {
    return (
      <div className={classNames(classes.invisibleContent, 'invisible-content')}>
        <Loading size={70} />
      </div>
    );
  }
  const onSubmitPurchaseForm = (data: IPurchaseAndSellForm) => {
    createSmartCoinOrderMutation.mutate({
      purchaseFormData: data,
      smartCoinName: $vm.smartCoinName || '',
      fiatCurrency: $vm.activeCurrency || '',
    });
  };

  const onRefreshRate = () => {
    downloadRatesQuery.refetch();
  };

  const onConfirm = () => {
    if (!$vm.createdOrder) return;
    confirmSmartCoinOrderMutation.mutate({ orderId: $vm.createdOrder.asJson.id });
  };

  const onCloseVerificationDialog = (result: boolean) => {
    if (result) {
      if (isMobile) {
        $vm.saveState();
        navigate(ROUTES.verification, { state: { redirect: pathname } });
      } else {
        setIsOpenKYCDialog(true);
      }
    }
    setIsOpenVerificationDialog(false);
  };

  const onCloseKYCDialog = () => {
    setIsOpenKYCDialog(false);
  };

  let form = <></>;
  if ($vm.activeScreen === Screen.CONFIRM_SCREEN) {
    form = (
      <ConfirmOrder
        isLoading={downloadRatesQuery.isFetching || confirmSmartCoinOrderMutation.isLoading}
        refreshRate={onRefreshRate}
        confirmOrder={onConfirm}
        onOpenDocument={() => setIsOpenDocumentDialog(true)}
        vm={$vm}
      />
    );
  } else if ($vm.activeScreen === Screen.PURCHASE_AND_SELL_FORM) {
    form = (
      <PurchaseAndSellForm
        vm={$vm}
        isLoading={downloadRatesQuery.isFetching}
        onOpenDocument={() => setIsOpenDocumentDialog(true)}
        onChangeCurrency={(currency) => {
          $vm.setActiveCurrency(currency);
          downloadRatesQuery.refetch();
        }}
        onSubmit={onSubmitPurchaseForm}
      />
    );
  } else {
    form = <></>;
  }

  return (
    <div className={classes.root}>
      {form}
      <VerificationDialogDialog
        isOpen={isOpenVerificationDialog}
        onClose={onCloseVerificationDialog}
      />
      <KYCDialogDialog isOpen={isOpenKYCDialog} onClose={onCloseKYCDialog} />
      <DocumentDialog
        isOpen={isOpenDocumentDialog}
        onClose={() => setIsOpenDocumentDialog(false)}
        title={t`Transaction Information`}
        type={$vm.documentTitle}
        url={$vm.document?.url || ''}
      />
    </div>
  );
});
export default appWithStyles(styles)(PurchaseAndSellBaseComponent);
