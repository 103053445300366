import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      height: _var(CSS_VARIABLES.appHeight),
      overflow: 'hidden',
      background: '#110E1D',
      [theme.breakpoints.up(Layout.laptop)]: {
        width: '100%',
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        background: 'transparent',
        '& .MuiImageListItem-img': {
          background: '#412D68',
        },
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        '& .MuiCircularProgress-root': {
          color: '#0A84FF !important',
        },
      },
    },
    changeAvatarBlock: {
      [theme.breakpoints.up(Layout.laptop)]: {
        borderRadius: '12px',
        width: '400px',
        background: 'transparent',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        width: '100%',
        height: '100%',
        borderRadius: '12px',
      },
    },
    changeAvatar: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    titleAndIconsBlock: {
      paddingTop: '20px',
      [theme.breakpoints.up(Layout.laptop)]: {
        paddingTop: '0px',
      },
    },
    backButton: {
      position: 'absolute',
      left: '20px',
      top: '20px',
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
    saveButtonBlock: {
      width: '100%',
      padding: '24px 20px 24px 20px',
      background: 'rgba(30, 29, 54, 0.50)',
      backdropFilter: 'blur(30px)',
      [theme.breakpoints.up(Layout.laptop)]: {
        background: 'transparent',
        padding: '0px 20px',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        position: 'absolute',
        bottom: '0px',
      },
    },
    imageList: {
      padding: '0px 20px 6px 20px',
      background: 'linear-gradient(180deg, #1B1629 0%, #000 100%)',
      marginTop: '14px',
      height: 'calc(100vh - 171px)',
      overflow: 'auto',
      [theme.breakpoints.up(Layout.laptop)]: {
        background: 'transparent',
        marginTop: '0px',
        height: 'calc(50vh - 56px)',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    title: {
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
  });
}
