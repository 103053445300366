import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DesktopContetLayout } from '@modules/new-private/layout/desktop-content-layout';
import { ROUTES } from '@shared/constants/routes';

// eslint-disable-next-line import/namespace,import/default
import Certificates from '../certificates/certficates';

import { styles } from './certificates-desktop-container.styles';

export interface CertificatesDesktopContainerProps extends AppWithStyles<typeof styles> {}

const CertificatesDesktopContainerComponent: FC<CertificatesDesktopContainerProps> = ({
  classes,
}) => {
  const routes = [
    { label: t`Settings`, path: ROUTES.desktopPrivate.settings.root },
    {
      label: t`Certificate of ownership`,
      path: ROUTES.desktopPrivate.settings.certificates,
      disabled: true,
    },
  ];

  return (
    <DesktopContetLayout links={routes}>
      <div className={classes.content}>
        <Certificates />
      </div>
    </DesktopContetLayout>
  );
};
export default appWithStyles(styles)(CertificatesDesktopContainerComponent);
