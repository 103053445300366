import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles({ breakpoints }: Theme) {
  return appCreateStyles({
    root: {
      height: '0px',
    },
    viewBlock: {
      padding: '20px 20px 20px 20px',
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      position: 'relative',
    },
    title: {
      color: _var(CSS_VARIABLES.colorPrimary100),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginTop: '13px',
      mmarginBottom: '20px',
    },
    drawer: {
      '& .MuiPaper-root': {
        height: 'auto',
        maxHeight: '90%',
        background: '#1E1D36',
      },
    },
    drawerContent: {
      maxHeight: '100%',
      overflow: 'auto',
    },
    type: {
      height: '45px',
      color: '#FFF',
      width: '100%',
      backgroundColor: '#1F2A4B',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [breakpoints.up(Layout.laptop)]: {
        backgroundColor: '#412D68',
      },
    },
    iframeBlock: {
      marginTop: '15px',
      marginBottom: '65px',
      [breakpoints.up(Layout.laptop)]: {
        width: '100%',
      },
    },
    iframe: {
      height: `65vh`,
      [breakpoints.up(Layout.laptop)]: {
        width: '100%',
        height: '55vh',
      },
      [breakpoints.down(Layout.laptop)]: {
        width: '90vmin',
      },
      '& .MuiImageList-root::-webkit-scrollbar': {
        width: '6px',
      },
      '& .MuiImageList-root::-webkit-scrollbar-thumb': {
        borderRadius: '12px',
        backgroundColor: '#5C36C2',
      },
      '& .MuiImageList-root::-webkit-scrollbar-track': {
        borderRadius: '12px',
        backgroundColor: 'transparent',
      },
      '& .MuiImageList-root': {
        scrollbarColor: '#5C36C2 #1F2A4B',
        scrollbarWidth: '6px',
      },
    },
    closeButton: {
      width: '100%',
      padding: '0px 20px',
      position: 'absolute',
      bottom: '20px',
    },
  });
}
