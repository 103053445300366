import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles() {
  return appCreateStyles({
    root: {
      width: '100%',
      height: _var(CSS_VARIABLES.appHeight),
      overflow: 'auto',
      position: 'relative',
    },
  });
}
