import { Dispatch } from 'react';

import { t } from '@lingui/macro';
import { IPurchaseAndSellForm } from '@modules/new-private/orders/purchase-and-sell/purchase-and-sell-form/purchase-and-sell-form.validator';
import { NavigateFunction } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { DocumentsTitle } from '@shared/enums/documents-title.enum';
import { TransactionOperationType } from '@shared/enums/transaction-operation-type.enum';
import { CreditCardListModel } from '@shared/models/credit-card/list-model';
import { CreateOrderDto, CreateOrderModel } from '@shared/models/orders/create-model';
import { MultiMetalPaymentType } from '@shared/models/orders/payment-method';
import { Currency } from '@shared/models/wallets/currency';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { BigNumber } from 'bignumber.js';

import { PurchaseAndSellViewModel } from './purchase-and-sell.vm';
import {transformToBaseCurrency} from "@shared/utils/currency-pipe";
import {amountToBigNumber, formatAmount} from "@shared/utils/metals";

export class SellViewModel extends PurchaseAndSellViewModel {
  private savedOrder: CreateOrderDto;

  constructor(data: {
    smartCoinName: string;
    setIsOpenVerificationDialog: Dispatch<boolean>;
    navigateFn: NavigateFunction;
  }) {
    super(data);
    this.isBuy = false;
  }

  get screenName() {
    return t`Sell`;
  }

  get round() {
    return BigNumber.ROUND_FLOOR;
  }

  get transactionTypeLabel() {
    return t`Sale`;
  }

  get documentTitle() {
    return DocumentsTitle.SELL;
  }

  get smartCoinNameTitle() {
    return transformToBaseCurrency(this.smartCoinName);
  }

  get noteMessage() {
    return t`Note that due to market rate fluctuations, there may be a slight difference in revenue of sell out`;
  }

  get isAvailabeCards() {
    return false;
  }

  get paymentMethodDescription() {
    return t`Select the account to which you would like to receive money`;
  }

  isValidOrderByBalance = (
    formData: IPurchaseAndSellForm,
    paymentMethod: WalletsListModel | CreditCardListModel | undefined,
  ) => {
    if (!paymentMethod) return false;
    const smartCoinWallet = this.wallets.find((w) => w.asJson.currency === this.smartCoinName);
    if (!smartCoinWallet || smartCoinWallet.asJson.balance < formData.smartCoinAmount) {
      return false;
    }
    return true;
  };

  isInsufficient(amount: number, count: number, fee: number = 0) {
    return false;
  }

  async downloadCards() {
    this.cards = [];
  }

  async createSmartCoinOrder(
    formData: IPurchaseAndSellForm,
    smartCoin: string,
    fiatCurrency: string,
  ) {
    this.savedOrder = {
      paymentMethod: formData.paymentType as MultiMetalPaymentType,
      paymentMethodId: formData.paymentMethodId,
      quantity: formData.smartCoinAmount,
      fiatCurrency,
      smartCoin,
    };
    let transactionFee = 0;
    if (formData.paymentType === MultiMetalPaymentType.card) {
      const fee = await this.walletsService.getTransactionFee(
        formData.paymentMethodId,
        TransactionOperationType.SELL,
        formData.fiatCurrencyAmount,
      );
      transactionFee = parseFloat(BigNumber(fee).toFixed(2, BigNumber.ROUND_CEIL));
    }
    this.createdOrder = new CreateOrderModel({
      id: '',
      quantity: formData.smartCoinAmount,
      smartCoin: smartCoin,
      transactionFee: transactionFee,
      totalValue: 0,
      fiatCurrency: fiatCurrency,
      isCard: formData.paymentType === MultiMetalPaymentType.card,
      paymentMethodId: formData.paymentMethodId,
    });
    this.selectPaymentMethod(formData);
    if (!this.selectedPaymentMethod) throw 'Payment method not found';
    this.purchaseFormData = formData;
    await this.downloadRates(this.activeCurrency as Currency);
  }

  async confirmSmartCoinOrder(orderId: string): Promise<{ id: string }> {
    try {
      const { id } = await this.ordersSmartCoinService.createSaleOrder(this.savedOrder);
      this.createdOrder?.setId(id);
      this.navigateFn(ROUTES.mobilePrivate.dashboard);
      this.handleSmartCoinOrderConfirmSuccess({ orderId }, { id });
      return { id };
    } catch (ex) {
      this.handleSmartCoinOrderError(ex);
      return { id: '' };
    }
  }

  handleSmartCoinOrderConfirmSuccess(params: { orderId: string }, result: { id: string } | void) {
    this.startTracking(result?.id || '');
  }

  calculateFiatCurrencyAmount(smartCoinAmount: string): string {
    if (!this.rate || !this.rate.asJson) return '0';
    const amountBigNumber = amountToBigNumber(smartCoinAmount);
    const rateBigNumber = new BigNumber(this.rate.asJson.price);
    const currentSum = amountBigNumber.multipliedBy(rateBigNumber);
    return formatAmount(currentSum.toFixed(2, BigNumber.ROUND_FLOOR), 2);
  }
}
