import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      padding: '20px 20px 0px 20px',
      width: '100%',
      height: '100%',
      borderRadius: '12px',
    },
    invisibleContent: {
      [theme.breakpoints.down(Layout.tablet)]: {
        height: '100%',
      },
    },
  });
}
