import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

import ExportButtonIcon from './img/export.svg';
export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.up(Layout.laptop)]: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      },
    },
    exportButton: {
      backgroundImage: `url(${String(ExportButtonIcon)})`,
      backgroundRepeat: 'no-repeat',
      width: '23px',
      height: '23px',
      position: 'absolute',
      right: '20px',
      top: '24px',
      [theme.breakpoints.up(Layout.laptop)]: {
        right: '5px',
        top: '5px',
      },
    },
    certificatesBlock: {
      [theme.breakpoints.up(Layout.laptop)]: {
        borderRadius: '12px',
        background: 'transparent',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        width: '100%',
        height: '100%',
        borderRadius: '12px',
      },
    },
    certificates: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    backButton: {
      position: 'absolute',
      left: '20px',
      top: '22px',
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
    placeholder: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    certificateBlock: {
      overflow: 'auto',
      marginTop: '14px',
      [theme.breakpoints.up(Layout.laptop)]: {
        maxHeight: 'calc(100vmax - 600px)',
        marginTop: '0px',
      },
    },
    titleBlock: {
      [theme.breakpoints.up(Layout.laptop)]: {
        width: 'calc(100% - 46px)',
        position: 'absolute',
        bottom: '10px',
      },
    },
    pdfDocument: {},
    pdfPage: {
      [theme.breakpoints.up(Layout.laptop)]: {
        '& + &': {
          marginTop: '20px',
        },
      },
      '& .react-pdf__Page__textContent': {
        display: 'none',
      },
      '& .react-pdf__Page__annotations': {
        display: 'none',
      },
      '&.react-pdf__Page': {
        backgroundColor: 'transparent !important',
      },
    },
    invisible: {
      display: 'none',
    },
    invisibleContent: {
      height: '100%',
    },
  });
}
