import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Box, Drawer, Modal, Theme, useMediaQuery } from '@mui/material';
import { CloseDrawerButton } from '@shared/components/new-design/close-drawer-button/close-drawer-button';
import AttentionSVG from '@shared/components/new-design/images/attantion-purple.svg';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { Layout } from '@shared/utils/layout';

import { styles } from './non-working-hours-dialog.styles';

export interface NonWorkingHoursDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: '#291945',
  border: '1px solid #473763',
  borderRadius: '10px',
  '&:focus-visible': {
    outline: 'none',
  },
};

const NonWorkingHoursDialogComponent: FC<NonWorkingHoursDialogProps> = ({
  classes,
  onClose,
  isOpen,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  const limitComponent = (
    <div className={classes.viewBlock}>
      <img className={classes.attentionIcon} src={String(AttentionSVG)}></img>
      <div className={classes.title}>
        {t`Gold Market Trading Hours`}
        <br />
        {t`AWST (UTC+8)`}
      </div>
      <div className={classes.description}>
        {t`Purchases & Redemptions are unavailable right now due to the gold market being closed.`}
      </div>
      <div className={classes.details}>
        <div className={classes.firstBlock}>
          <span className={classes.bold}>{t`Gold market Trading Hours`}</span>
          <br />
          <span>{t`Mon - Sat  | 6am - 5am AWST (UTC+8)`}</span>
          <br />
          <span>{t`(1 hour closure each day between 5am - 6am)`}</span>
        </div>
        <div className={classes.secondBlock}>
          <span className={classes.bold}>{t`Gold Market Closed`}</span>
          <br />
          <span>{t`Sat - Mon  |  5am - 6am AWST (UTC+8)`}</span>
        </div>
      </div>
      <div className={classes.submit}>
        <SubmitButton label={t`Close`} onSubmit={onClose} />
      </div>
    </div>
  );

  let content = <></>;

  if (isMobile) {
    content = (
      <Drawer anchor={'bottom'} open={isOpen} className={classes.drawer} onClose={onClose}>
        <div className={classes.drawerContent}>{limitComponent}</div>
        <CloseDrawerButton onClick={onClose} />
      </Drawer>
    );
  } else {
    content = (
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{limitComponent}</Box>
      </Modal>
    );
  }
  return <div className={classes.root}>{content}</div>;
};

export default appWithStyles(styles)(NonWorkingHoursDialogComponent);
