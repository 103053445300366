import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';

export function styles(theme: Theme) {
  return appCreateStyles({
    content: {
      padding: '20px 20px 60px 20px',
      height: 'fit-content',
      borderRadius: '12px',
      maxHeight: '75vh',
      overflow: 'auto',
    },
    invisibleContent: {
      height: '100%',
    },
  });
}
