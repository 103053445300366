import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Box, Modal } from '@mui/material';
import classNames from 'classnames';

import DeleteIcon from './img/delete.svg';

import { styles } from './delete-card-dialog.styles';

export interface DeleteCardDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 270,
  border: 'none',
  borderRadius: '14px',
  paddingTop: '20px',
  background: 'rgba(37, 37, 37, 0.82)',
  outline: 'none',
};

const DeleteCardDialogComponent: FC<DeleteCardDialogProps> = ({
  classes,
  onClose,
  isOpen,
  onDelete,
}) => {
  const handleDelete = () => {
    onDelete && onDelete();
    onClose && onClose();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const content = (
    <div className={classes.viewBlock}>
      <div className={classNames(classes.titleBlock, classes.menuItem)}>
        <div className={classes.image}>
          <img src={String(DeleteIcon)} />
        </div>
        <div className={classes.title}>{t`Payment Card Deletion`}</div>
        <div
          className={classes.description}
        >{t`Are You sure you want to delete this payment card?`}</div>
      </div>
      <div
        className={classNames(classes.logoutButton, classes.menuItem)}
        onClick={handleDelete}
      >{t`Delete`}</div>
      <div
        className={classNames(classes.cancelButton, classes.menuItem)}
        onClick={handleClose}
      >{t`Cancel`}</div>
    </div>
  );

  return (
    <div className={classes.root}>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="delete-card-label"
        aria-describedby="delete-card"
      >
        <Box sx={style}>{content}</Box>
      </Modal>
    </div>
  );
};

export default appWithStyles(styles)(DeleteCardDialogComponent);
