import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: '0px',
    },
    viewBlock: {
      padding: '20px 20px 20px 20px',
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    attentionIcon: {
      width: '78px',
    },
    title: {
      color: '#7543FF',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginTop: '13px',
    },
    description: {
      color: 'rgba(255, 255, 255, 0.60)',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '170%',
      letterSpacing: '0.3px',
      width: '315px',
      marginTop: '24px',
    },
    details: {
      marginTop: '16px',
      color: 'rgba(255, 255, 255, 0.60)',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '180%',
      letterSpacing: '0.3px',
      marginBottom: '75px',
    },
    firstBlock: {
      borderRadius: '12px 12px 0px 0px',
      background: 'rgba(255, 255, 255, 0.06)',
      height: '88px',
      padding: '10px 16px',
    },
    secondBlock: {
      marginTop: '1px',
      height: '72px',
      borderRadius: '0px 0px 12px 12px',
      background: 'rgba(255, 255, 255, 0.06)',
      padding: '16px',
    },
    bold: {
      fontWeight: 600,
    },
    submit: {
      position: 'absolute',
      bottom: '20px',
      width: 'calc(100% - 40px)',
    },
    drawer: {
      '& .MuiPaper-root': {
        maxHeight: '90%',
        height: 'auto',
        background: '#1E1D36',
      },
    },
    drawerContent: {
      maxHeight: '100%',
      overflow: 'auto',
    },
  });
}
