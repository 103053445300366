import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { Currency } from '@shared/models/wallets/currency';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { IProductsVM } from '@shared/types/products-vm';
import { IRatesVM } from '@shared/types/rates-vm';
import { IWalletsService } from '@shared/types/wallets-service';

export class CurrencyViewModel {
  private walletsService = appInject<IWalletsService>(DI_TOKENS.walletsService);
  private productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
  private ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);
  private _wallets: Array<WalletsListModel> = [];

  get activeCurrency() {
    return this.ratesVM.currency;
  }

  setActiveCurrency(currency: string) {
    this.ratesVM.currency = currency as Currency;
  }

  get wallets() {
    return this._wallets;
  }

  getProductByCurrency(currency: string) {
    return this.productsVM.getProductByCurrency(currency);
  }

  getProductTypeByCurrency(currency: string) {
    return this.productsVM.getProductTypeByCurrency(currency);
  }

  fetchWallets = async () => {
    this._wallets = await this.walletsService.getList(this.ratesVM.currency, true);
  };
}
