import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DesktopContetLayout } from '@modules/new-private/layout/desktop-content-layout';
import { useParams } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { SmartCoinOrderType } from '@shared/models/smart-coin-order-status/smart-coin-order-type';
import { BreadcrumbsLink } from '@shared/types/breadcrumbs-links';

import PurchaseAndSellBase from '../purchase-and-sell-base/purchase-and-sell-base';

import { styles } from './purchase-and-sell-desktop.styles';

export interface PurchaseProps extends AppWithStyles<typeof styles> {}

const PurchaseAndSellDesktopComponent: FC<PurchaseProps> = ({}) => {
  const { orderType } = useParams();

  const routes = [{ label: t`Accounts`, path: ROUTES.desktopPrivate.dashboard }];

  if (orderType === SmartCoinOrderType.PURCHASE) {
    routes.push({
      label: t`Purchase`,
      path: ROUTES.desktopPrivate.wallets.children.order(),
      disabled: true,
    } as BreadcrumbsLink);
  } else {
    routes.push({
      label: t`Withdraw`,
      path: ROUTES.desktopPrivate.wallets.children.order(),
      disabled: true,
    } as BreadcrumbsLink);
  }

  return (
    <DesktopContetLayout links={routes}>
      <PurchaseAndSellBase />
    </DesktopContetLayout>
  );
};
export default appWithStyles(styles)(PurchaseAndSellDesktopComponent);
