import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DesktopContetLayout } from '@modules/new-private/layout/desktop-content-layout';
import ChangeAvatar from '@modules/new-private/settings/components/change-avatar/change-avatar/change-avatar';
import { ROUTES } from '@shared/constants/routes';

// eslint-disable-next-line import/namespace,import/default

import { styles } from './change-avatar-desktop-container.styles';

export interface ChangeAvatarDesktopContainerProps extends AppWithStyles<typeof styles> {}

const ChangeAvatarDesktopContainerComponent: FC<ChangeAvatarDesktopContainerProps> = ({
  classes,
}) => {
  const routes = [
    { label: t`Settings`, path: ROUTES.desktopPrivate.settings.root },
    { label: t`Personal Information`, path: ROUTES.desktopPrivate.settings.personalInfo },
    {
      label: t`Choose Your Avatar`,
      path: ROUTES.desktopPrivate.settings.changeAvatar,
      disabled: true,
    },
  ];

  return (
    <DesktopContetLayout links={routes}>
      <div className={classes.content}>
        <ChangeAvatar />
      </div>
    </DesktopContetLayout>
  );
};
export default appWithStyles(styles)(ChangeAvatarDesktopContainerComponent);
