import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import DeleteButtonIcon from '@shared/components/new-design/images/delete-icon.svg';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

import EmptyStateIcon from './img/img.png';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.up(Layout.laptop)]: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        '& .card-root': {
          backgroundColor: '#412D68',
        },
      },
    },
    deleteButton: {
      backgroundImage: `url(${String(DeleteButtonIcon)})`,
      backgroundRepeat: 'no-repeat',
      width: '23px',
      height: '23px',
      position: 'absolute',
      right: '17px',
      top: '28px',
      cursor: 'pointer',
      [theme.breakpoints.down(Layout.tablet)]: {
        right: '20px',
        top: '24px',
      },
    },
    title: {
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    backButton: {
      position: 'absolute',
      left: '20px',
      top: '20px',
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
    cardsBlock: {
      [theme.breakpoints.up(Layout.laptop)]: {
        width: '100%',
        height: '100%',
        borderRadius: '12px',
        background: 'transparent',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        width: '100%',
        height: '100%',
        borderRadius: '12px',
      },
    },
    cards: {
      marginTop: '20px',
      marginBottom: '20px',
      maxHeight: '450px',
      overflow: 'auto',
    },
    cardsView: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    opacity: {},
    invisible: {
      display: 'none',
    },
    addCard: {
      paddingBottom: '10px',
      [theme.breakpoints.up(Layout.laptop)]: {
        paddingBottom: '0px',
      },
    },
    cardsTopPart: {
      height: '100%',
      minHeight: '244px',
    },
    list: {
      marginTop: '20px',
    },
    paymentMethod: {
      height: '78px',
      '& + &': {
        marginTop: '10px',
      },
    },
    emptyState: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(Layout.laptop)]: {
        height: '211px',
      },
    },
    emptyStateImg: {
      width: '103px',
      height: '87px',
      backgroundImage: `url(${EmptyStateIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      marginBottom: '35px',
    },
    emptyStateText: {
      color: '#778',
      textAlign: 'center',
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '165%',
      letterSpacing: '0.2px',
    },
    invisibleContent: {
      height: '100%',
    },
  });
}
