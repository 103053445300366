import React, { useMemo, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DeleteCardDialog } from '@modules/new-private/settings/components/cards/components/delete-card-dialog';
import { Loading } from '@shared/components/loading';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { OutlinedButton } from '@shared/components/new-design/outlined-button';
import { Card } from '@shared/components/new-design/select-payment-method/components/card/card';
import { AddCardStripe } from '@shared/components/payments/stripe/add-card-stripe-component';
import { AddCardWorldpay } from '@shared/components/payments/worldpay/add-card-worldpay-component';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { HttpErrorResponse } from '@shared/models/error/http-error-response';
import { PaymentProviderEnum } from '@shared/types/payments/payment-provider.enum';
import classNames from 'classnames';
import { useMutation, useQuery } from 'react-query';

import { CardsViewModel } from './cards.vm';

import { styles } from './cards.styles';

export interface CardsProps extends AppWithStyles<typeof styles> {}

const CardsComponent: React.FC<CardsProps> = appObserver(({ classes }) => {
  const $vm = useMemo(() => new CardsViewModel(), []);
  const [isOpenNewCardDialog, setIsOpenNewCardDialog] = useState<boolean>(false);
  const [isActivateDeletingMode, setIsActivateDeletingMode] = useState<boolean>(false);
  const [deletingCardId, setDeletingCardId] = useState<string>('');
  const navigate = useNavigate();

  const fetchCardsQuery = useQuery(['download-cards-list'], () => $vm.downloadCards(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    onError: () => {},
  });

  const addNewCardMutation = useMutation(
    (data: { cardInfo: any; save: boolean }) => $vm.addNewCard(data.cardInfo, !data.save),
    {
      onSuccess: (savedCard) => {
        $vm.addCardToList(savedCard);
        setIsOpenNewCardDialog(false);
        fetchCardsQuery.refetch();
        $vm.showSuccessMessage(t`Card successfully added.`);
      },
      onError: (e: HttpErrorResponse | any) => {
        $vm.showErrorMessage(t`Your card was declined.`);
      },
    },
  );

  const deleteCardMutation = useMutation((data: { id: string }) => $vm.deleteCard(data.id), {
    onSuccess: () => {
      setDeletingCardId('');
      setIsActivateDeletingMode(false);
      $vm.showSuccessMessage(t`Card was removed.`);
    },
    onError: () => {
      $vm.showErrorMessage(t`Something went wrong, please try again`);
    },
  });

  const navigateToSettings = () => {
    navigate(ROUTES.mobilePrivate.settings.root);
  };

  let content = <></>;

  if (fetchCardsQuery.isLoading) {
    return (
      <div className={classNames(classes.root, classes.invisibleContent, 'invisible-content')}>
        <Loading size={70} />
      </div>
    );
  } else if ($vm.cards.length === 0) {
    content = (
      <div className={classes.emptyState}>
        <div className={classes.emptyStateImg}></div>
        <div className={classes.emptyStateText}>{t`It seems you have no cards yet`}</div>
      </div>
    );
  } else {
    content = (
      <div className={classes.cards}>
        <div className={classes.list}>
          {$vm.cards.map((card) => {
            return (
              <div className={classes.paymentMethod}>
                <Card
                  brand={card.asJson.brand}
                  lastDigits={
                    card.asJson.isTemporary
                      ? `${card.asJson.last4} ` + t`(not saved)`
                      : card.asJson.last4
                  }
                  isDeletingMode={isActivateDeletingMode}
                  isActiveArrowRightButton={!isActivateDeletingMode}
                  onDelete={() => setDeletingCardId(card.asJson.id)}
                  isExpired={card.asJson.isExpired}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  let paymentComponent = <div></div>;
  if ($vm.activePaymentSystem() === PaymentProviderEnum.STRIPE) {
    paymentComponent = (
      <AddCardStripe
        showSave
        isOpen={isOpenNewCardDialog}
        loading={fetchCardsQuery.isFetching}
        onSave={(cardInfo, save) => addNewCardMutation.mutate({ cardInfo, save })}
        onCancel={() => setIsOpenNewCardDialog(false)}
        confirmButtonLabel={t`Proceed`}
      />
    );
  } else if ($vm.activePaymentSystem() === PaymentProviderEnum.WORLDPAY) {
    paymentComponent = (
      <AddCardWorldpay
        showSave
        isOpen={isOpenNewCardDialog}
        loading={addNewCardMutation.isLoading}
        error={addNewCardMutation.isError}
        onSave={(cardInfo, save) => addNewCardMutation.mutate({ cardInfo, save })}
        onCancel={() => setIsOpenNewCardDialog(false)}
        confirmButtonLabel={t`Continue`}
      />
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.cardsBlock}>
        <div className={classes.cardsView}>
          <div className={classes.cardsTopPart}>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToSettings} />
            </div>
            <div className={classes.title}>{t`Payment methods`}</div>
            <div
              className={classNames(classes.deleteButton, {
                [classes.invisible]: $vm.cards.length === 0,
              })}
              onClick={() => setIsActivateDeletingMode(!isActivateDeletingMode)}
            ></div>
            {content}
          </div>
          <div className={classes.addCard}>
            <OutlinedButton
              label={`+${t`Add new card`}`}
              variant="outlined"
              onSubmit={() => setIsOpenNewCardDialog(true)}
              isLoading={
                (fetchCardsQuery.isFetching && !fetchCardsQuery.isLoading) ||
                addNewCardMutation.isLoading ||
                deleteCardMutation.isLoading
              }
            />
          </div>
        </div>
      </div>
      {paymentComponent}
      <DeleteCardDialog
        isOpen={Boolean(deletingCardId)}
        onClose={() => setDeletingCardId('')}
        onDelete={() => deleteCardMutation.mutate({ id: deletingCardId })}
      />
    </div>
  );
});

export default appWithStyles(styles)(CardsComponent);
