import React, { useMemo } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Loading } from '@shared/components/loading';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { WalletInfo } from '@shared/components/new-design/wallet-info';
import { useNavigate } from '@shared/components/router';
import { comingSoonProducts } from '@shared/constants/coming-soon';
import { ROUTES } from '@shared/constants/routes';
import { ProductType } from '@shared/models/products/product-model';
import classNames from 'classnames';
import { useQuery } from 'react-query';

import { CurrencyViewModel } from './currency.vm';

import { styles } from './curency.styles';

export interface CurrencyProps extends AppWithStyles<typeof styles> {}

const CurrencyComponent: React.FC<CurrencyProps> = appObserver(({ classes }) => {
  const $vm = useMemo(() => new CurrencyViewModel(), []);
  const navigate = useNavigate();

  const fetchWalletsQuery = useQuery(['fetch-wallets-list'], () => $vm.fetchWallets(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const navigateToSettings = () => {
    navigate(ROUTES.mobilePrivate.settings.root);
  };

  let content = <></>;

  if (fetchWalletsQuery.isFetching) {
    return (
      <div className={classNames(classes.root, classes.invisibleContent, 'invisible-content')}>
        <Loading size={70} />
      </div>
    );
  } else {
    content = (
      <div className={classes.content}>
        <div
          className={classes.description}
        >{t`Select the currency you want all data to be displayed in. You can change the currency at any time`}</div>
        {$vm.wallets
          .filter((w) => $vm.getProductTypeByCurrency(w.asJson.currency) === ProductType.FIAT_COIN)
          .map((w) => {
            const product = $vm.getProductByCurrency(w.asJson.currency as string);
            if (!product) return <></>;
            return (
              <div
                className={classNames(classes.section, classes.pointer)}
                onClick={() => $vm.setActiveCurrency(product.asJson.name)}
              >
                <WalletInfo
                  wallet={w}
                  product={product}
                  hasRadioButton={true}
                  isActiveRadioButton={$vm.activeCurrency === product.asJson.name}
                  isRelationToBaseCurrency={true}
                />
                <div
                  className={classes.description}
                >{t`Wealthstack token based on ${product.asJson.baseCurrency} price`}</div>
              </div>
            );
          })}
        {comingSoonProducts.map((product) => {
          return (
            <div className={classes.section}>
              <WalletInfo
                wallet={product as any}
                product={product as any}
                hasRadioButton={true}
                unactive={true}
                isActiveRadioButton={false}
                isRelationToBaseCurrency={true}
              />
              <div className={classes.description}>{t`Coming soon...`}</div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.securityBlock}>
        <div className={classes.security}>
          <div>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToSettings} />
            </div>
            <div className={classes.title}>{t`System currency`}</div>
            {content}
          </div>
        </div>
      </div>
    </div>
  );
});

export default appWithStyles(styles)(CurrencyComponent);
