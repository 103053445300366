import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import Currency from '../currency/currency';

import { styles } from './currency-mobile-container.styles';

export interface CurrencyMobileContainerProps extends AppWithStyles<typeof styles> {}

const CurrencyMobileContainerComponent: React.FC<CurrencyMobileContainerProps> = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Currency />
    </div>
  );
};

export default appWithStyles(styles)(CurrencyMobileContainerComponent);
