import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: '0px',
    },
    viewBlock: {},
    titleBlock: {
      color: '#fff',
      textAlign: 'center',
      minHeight: '101px !important',
      flexDirection: 'column',
    },
    title: {
      fontSize: '17px',
      fontWeight: 700,
      lineHeight: '22px',
      marginBottom: '10px',
    },
    description: {
      padding: '10px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '25.5px',
      marginBottom: '16px',
    },
    menuItem: {
      cursor: 'pointer',
      minHeight: '44px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px 36px',
      '& + &': {
        borderTop: '0.333px solid rgba(84, 84, 88, 0.65)',
      },
    },
    logoutButton: {
      color: '#0A84FF',
      textAlign: 'center',
      fontSize: '17px',
      fontWeight: 700,
      lineHeight: '22px',
    },
    cancelButton: {
      color: '#0A84FF',
      textAlign: 'center',
      fontSize: '17px',
      fontWeight: 400,
      lineHeight: '22px',
      letterSpacing: '-0.4px',
    },
    image: {
      marginBottom: '14px',
    },
  });
}
