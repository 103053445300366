import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import ChangeAvatar from '@modules/new-private/settings/components/change-avatar/change-avatar/change-avatar';

import { styles } from './change-avatar-mobile-container.styles';

export interface MobileChangeAvatarProps extends AppWithStyles<typeof styles> {}

const MobileChangeAvatar: React.FC<MobileChangeAvatarProps> = ({ classes }) => {
  return (
    <div className={classes.root}>
      <ChangeAvatar />
    </div>
  );
};

export default appWithStyles(styles)(MobileChangeAvatar);
