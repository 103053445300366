import { Dispatch } from 'react';

import { t } from '@lingui/macro';
import { IPurchaseAndSellForm } from '@modules/new-private/orders/purchase-and-sell/purchase-and-sell-form/purchase-and-sell-form.validator';
import { NavigateFunction } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { DocumentsTitle } from '@shared/enums/documents-title.enum';
import { TransactionOperationType } from '@shared/enums/transaction-operation-type.enum';
import { CreditCardListModel } from '@shared/models/credit-card/list-model';
import { CreateOrderDto, CreateOrderModel } from '@shared/models/orders/create-model';
import { MultiMetalPaymentType } from '@shared/models/orders/payment-method';
import { Currency } from '@shared/models/wallets/currency';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { smartCoinOrderStatusPipe } from '@shared/pipes/smart-coin-order-status.pipe';
import {
  SmartCoinOrderStatusExternal,
  SmartCoinOrderStatusInternal,
} from '@shared/types/smart-coin-order-status';
import { transformToBaseCurrency } from '@shared/utils/currency-pipe';
import { amountToBigNumber, amountToNumber, formatAmount } from '@shared/utils/metals';
import { wait } from '@shared/utils/wait';
import { BigNumber } from 'bignumber.js';

import { PurchaseAndSellViewModel } from './purchase-and-sell.vm';

export class PurchaseViewModel extends PurchaseAndSellViewModel {
  private savedOrder: CreateOrderDto;
  constructor(data: {
    smartCoinName: string;
    setIsOpenVerificationDialog: Dispatch<boolean>;
    navigateFn: NavigateFunction;
  }) {
    super(data);
    this.isBuy = true;
  }

  get screenName() {
    return t`Buy`;
  }

  get round() {
    return BigNumber.ROUND_CEIL;
  }

  get smartCoinNameTitle() {
    return transformToBaseCurrency(this.smartCoinName);
  }

  get transactionTypeLabel() {
    return t`Purchase`;
  }

  get documentTitle() {
    return DocumentsTitle.PURCHASE;
  }

  get noteMessage() {
    return t`Note that due to market rate fluctuations, there may be a slight difference in cost of purchase`;
  }

  isValidOrderByBalance = (
    formData: IPurchaseAndSellForm,
    paymentMethod: WalletsListModel | CreditCardListModel | undefined,
  ) => {
    if (!paymentMethod) return false;
    if (paymentMethod instanceof CreditCardListModel) return true;
    return (paymentMethod as WalletsListModel).asJson.balance >= formData.fiatCurrencyAmount;
  };
  async createSmartCoinOrder(
    formData: IPurchaseAndSellForm,
    smartCoin: string,
    fiatCurrency: string,
  ) {
    this.savedOrder = {
      paymentMethod: formData.paymentType as MultiMetalPaymentType,
      paymentMethodId: formData.paymentMethodId,
      quantity: formData.smartCoinAmount,
      fiatCurrency,
      smartCoin,
    };
    let transactionFee = 0;
    if (formData.paymentType === MultiMetalPaymentType.card) {
      const fee = await this.walletsService.getTransactionFee(
        formData.paymentMethodId,
        TransactionOperationType.PURCHASE,
        formData.fiatCurrencyAmount,
      );
      transactionFee = parseFloat(BigNumber(fee).toFixed(2, BigNumber.ROUND_CEIL));
    }
    this.createdOrder = new CreateOrderModel({
      id: '',
      quantity: formData.smartCoinAmount,
      smartCoin: smartCoin,
      transactionFee: transactionFee,
      totalValue: 0,
      fiatCurrency: fiatCurrency,
      isCard: formData.paymentType === MultiMetalPaymentType.card,
      paymentMethodId: formData.paymentMethodId,
    });
    this.selectPaymentMethod(formData);
    if (!this.selectedPaymentMethod) throw 'Payment method not found';
    this.purchaseFormData = formData;
    await this.downloadRates(this.activeCurrency as Currency);
    this.checkLimits(
      this.savedOrder.paymentMethod,
      amountToNumber(this.calculateFiatCurrencyAmount(String(this.createdOrder.asJson.quantity))),
      fiatCurrency,
    );
  }

  async confirmSmartCoinOrder(): Promise<{ id: string }> {
    try {
      const order = await this.ordersSmartCoinService.createPurchaseOrder(this.savedOrder);
      this.createdOrder?.setId(order.asJson.id);
      await wait(4);
      const orderStatus = await this.ordersSmartCoinService.getOrderStatus(order.asJson.id);
      const externalStatus = smartCoinOrderStatusPipe(
        orderStatus.asJson.status as SmartCoinOrderStatusInternal,
      );
      let isApproved3DSecure: boolean = false;
      if (externalStatus === SmartCoinOrderStatusExternal.SECURE_3D) {
        try {
          await this.paymentService.handle3DSercure(
            orderStatus.paymentResult.response,
            order.asJson.id,
          );
          await this.confirmPaymentTransaction(
            orderStatus.asJson.paymentResult.transactionId,
            order.asJson.id,
          );
          isApproved3DSecure = true;
        } catch {
          isApproved3DSecure = false;
          await this.declinePaymentTransaction(
            orderStatus.asJson.paymentResult.transactionId,
            order.asJson.id,
          );
        }
      }
      this.navigateFn(ROUTES.mobilePrivate.dashboard);
      this.smartCoinTrackingService.startTracking({
        orderId: order.asJson.id,
        amount: this.createdOrder?.asJson.quantity || 0,
        smartCoin: this.smartCoinName,
        isBuy: this.isBuy,
        secure3DAlreadyShown: isApproved3DSecure,
        hideFirstMessage: true,
      });
      return { id: order.asJson.id };
    } catch (ex) {
      this.handleSmartCoinOrderError(ex);
      return { id: '' };
    }
  }

  async confirmPaymentTransaction(transactionId: string, orderId: string): Promise<void> {
    return this.walletsService.confirmPaymentTransaction(transactionId, orderId);
  }

  async declinePaymentTransaction(transactionId: string, orderId: string): Promise<void> {
    return this.walletsService.declinePaymentTransaction(transactionId, orderId);
  }

  handleSmartCoinOrderConfirmSuccess(params: { orderId: string }, result: { id: string } | void) {
    this.startTracking(this.createdOrder?.asJson.id || 'unknown');
  }

  calculateFiatCurrencyAmount(smartCoinAmount: string): string {
    if (!this.rate || !this.rate.asJson) return '0';
    const amountBigNumber = amountToBigNumber(smartCoinAmount);
    const rateBigNumber = new BigNumber(this.rate.asJson.price);
    const currentSum = amountBigNumber.multipliedBy(rateBigNumber);
    return formatAmount(currentSum.toFixed(2, BigNumber.ROUND_CEIL), 2);
  }
}
