import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import Certificates from '../certificates/certficates';

import { styles } from './certificates-mobile-container.styles';

export interface CertificatesMobileContainerProps extends AppWithStyles<typeof styles> {}

const CertificatesMobileContainerComponent: React.FC<CertificatesMobileContainerProps> = ({
  classes,
}) => {
  return (
    <div className={classes.root}>
      <Certificates />
    </div>
  );
};

export default appWithStyles(styles)(CertificatesMobileContainerComponent);
