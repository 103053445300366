import React, { useMemo, KeyboardEvent } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { PurchaseViewModel } from '@modules/new-private/orders/purchase-and-sell/purchase.vm';
import { SellFiatTokenViewModel } from '@modules/new-private/orders/purchase-and-sell/sell-fiat-token.vm';
import { SellViewModel } from '@modules/new-private/orders/purchase-and-sell/sell.vm';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { OutlinedButton } from '@shared/components/new-design/outlined-button';
import { Card } from '@shared/components/new-design/select-payment-method/components/card/card';
import { Wallet } from '@shared/components/new-design/select-payment-method/components/wallet/wallet';
import SubmitButton from '@shared/components/new-design/submit-button';
import { CreditCardListModel } from '@shared/models/credit-card/list-model';
import { MultiMetalPaymentType } from '@shared/models/orders/payment-method';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { formatAmount } from '@shared/utils/metals';

import { styles } from './confirm-order.styles';

export interface ConfirmOrderProps extends AppWithStyles<typeof styles> {
  isLoading?: boolean;
  refreshRate: () => void;
  confirmOrder: () => void;
  onOpenDocument?: () => void;
  vm: PurchaseViewModel | SellViewModel | SellFiatTokenViewModel;
}

const ConfirmOrderComponent: React.FC<ConfirmOrderProps> = appObserver(
  ({ classes, isLoading, refreshRate, confirmOrder, vm, onOpenDocument }) => {
    const $vm = useMemo(() => vm, []);

    if (!$vm.createdOrder) {
      return <></>;
    }

    const costOfPurchaseBigNumber = $vm.calculateCostOfPurchase(
      $vm.rate?.asJson.price || 0,
      $vm.createdOrder.asJson.quantity,
    );

    const totalBigNumber = $vm.calculateTotal(
      $vm.rate?.asJson.price || 0,
      $vm.createdOrder.asJson.quantity,
      $vm.createdOrder.asJson.transactionFee,
    );

    const isInsufficient = $vm.isInsufficient(
      $vm.rate?.asJson.price || 0,
      $vm.createdOrder.asJson.quantity,
      $vm.createdOrder.asJson.transactionFee,
    );

    const confirmButton = (
      <SubmitButton
        label={$vm.confirmScreenTitle}
        disabled={$vm.isDisableConfirmScreenSubmitButton}
        isLoading={isLoading}
        onSubmit={confirmOrder}
      />
    );
    const updateButton = (
      <OutlinedButton
        label={t`Update Order Price`}
        disabled={false}
        isLoading={isLoading}
        onSubmit={refreshRate}
      />
    );

    const actionButton = $vm.isExpiredRate ? updateButton : confirmButton;

    const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.code === 'Enter' && !$vm.isExpiredRate && !isInsufficient) {
        confirmOrder();
      }
    };

    const walletComponent = (
      <Wallet
        isInsufficient={isInsufficient}
        currency={($vm.selectedPaymentMethod as WalletsListModel)?.asJson.currency || ''}
        balance={minDigitsAfterDot(
          amountPipe(($vm.selectedPaymentMethod as WalletsListModel)?.asJson.balance || 0),
        )}
      />
    );

    const cardComponent = (
      <Card
        brand={($vm.selectedPaymentMethod as CreditCardListModel)?.asJson.brand || ''}
        lastDigits={($vm.selectedPaymentMethod as CreditCardListModel)?.asJson.last4 || ''}
        isExpired={($vm.selectedPaymentMethod as CreditCardListModel)?.asJson.isExpired || false}
      />
    );

    const paymentMethodComponent =
      $vm.purchaseFormData?.paymentType === MultiMetalPaymentType.deposit
        ? walletComponent
        : cardComponent;

    const message = $vm.isExpiredRate ? (
      <div className={`${classes.messageBlock} ${classes.warning}`}>
        <div className={classes.message}>
          {t`The GOLD price has slightly changed. Please press "Update Order Price" button to reload the new price of this order.`}
        </div>
        <div className={classes.icon}></div>
      </div>
    ) : (
      <div className={`${classes.messageBlock} ${classes.info}`}>
        <div className={classes.message}>{$vm.noteMessage}</div>
        <div className={classes.icon}></div>
      </div>
    );

    return (
      <div className={classes.root} onKeyDown={handleKeyPress}>
        <div className={classes.backButton}>
          {isLoading ? <></> : <BackButton onClick={() => $vm.resetOrder()} />}
        </div>
        <div className={classes.infoBlock}>
          <div className={classes.title}>{$vm.confirmScreenTitle}</div>
          <div
            className={classes.hintButton}
            onClick={() => onOpenDocument && onOpenDocument()}
          ></div>
          <div className={classes.typeOfTransaction}>
            <div className={classes.infoField}>
              <div className={classes.fieldTitle}>{t`Type of Transaction`}</div>
              <div className={classes.value}>{$vm.transactionTypeLabel}</div>
            </div>
          </div>
          <div className={classes.generalInfoBlock}>
            <div className={classes.generalInfoField}>
              <div className={classes.fieldTitle}>{t`Quantity`} *</div>
              <div className={classes.value}>
                {minDigitsAfterDot(amountPipe($vm.createdOrder.asJson.quantity, 8))}{' '}
                {$vm.createdOrder.asJson.smartCoin}
              </div>
            </div>
            <div className={classes.generalInfoField}>
              <div className={classes.fieldTitle}>{$vm.confirmScreenRateTitle}</div>
              <div className={classes.value}>{$vm.confirmScreenRateValue}</div>
            </div>
            <div className={classes.generalInfoField}>
              <div className={classes.fieldTitle}>
                {$vm.isPurchase ? t`Purchase Price` : t`Sale price`}
              </div>
              <div className={classes.value}>
                {minDigitsAfterDot(formatAmount(costOfPurchaseBigNumber.toFixed(2, $vm.round), 2))}{' '}
                {$vm.receiptCurrency}
              </div>
            </div>
            <div className={`${classes.generalInfoField} ${classes.fee}`}>
              <div className={classes.fieldTitle}>{t`Payment Card Fee`}</div>
              <div className={classes.value}>
                {$vm.isPurchase || $vm.createdOrder.asJson.transactionFee === 0 ? '' : '-'}{' '}
                {minDigitsAfterDot(formatAmount($vm.createdOrder.asJson.transactionFee, 2))}{' '}
                {$vm.receiptCurrency}
              </div>
            </div>
          </div>
          <div className={classes.total}>
            <div className={classes.infoField}>
              <div className={classes.bold}>{t`Total`}:</div>
              <div className={classes.bold}>
                {formatAmount(totalBigNumber.toFixed(2, $vm.round), 2)} {$vm.receiptCurrency}{' '}
              </div>
            </div>
          </div>
          <div className={classes.paymentMethod}>{paymentMethodComponent}</div>
          {message}
          <div className={classes.footnote}>{$vm.footNoteFromConfirmationScreen}</div>
        </div>
        <div className={classes.submit}>{actionButton}</div>
      </div>
    );
  },
);

export default appWithStyles(styles)(ConfirmOrderComponent);
