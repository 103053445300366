import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { AvatarImageModel } from '@shared/models/users/avatar-image';
import { UserDetailsModel } from '@shared/models/users/details-model';
import { EmptyAvatarModel } from '@shared/models/users/empty-avatar';
import { IUsersService } from '@shared/types/users-service';

export class ChangeAvatarViewModel {
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private _userDetails: UserDetailsModel;
  private _myEmptyAvatar: EmptyAvatarModel;
  private _avatarsList: Array<AvatarImageModel>;

  get myDetails() {
    return this._userDetails;
  }

  get emptyAvatar() {
    return this._myEmptyAvatar;
  }

  get avatars() {
    return this._avatarsList;
  }

  async loadData() {
    [this._myEmptyAvatar, this._avatarsList, this._userDetails] = await Promise.all([
      this.fetchEmptyAvatar(),
      this.fetchAvatarsList(),
      this.fetchMyUserDetails(),
    ]);
  }

  async fetchMyUserDetails() {
    return await this.usersService.getMyUserDetails();
  }

  async fetchAvatarsList(): Promise<Array<AvatarImageModel>> {
    return await this.usersService.getAvatarsList();
  }

  async fetchEmptyAvatar(): Promise<EmptyAvatarModel> {
    return await this.usersService.getMyEmptyAvatar();
  }

  async saveAvatar(avatarId: string | null): Promise<void> {
    return await this.usersService.saveAvatar(avatarId);
  }
}
