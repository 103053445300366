import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import ContectSupportIcon from '@shared/components/new-design/images/info-icon-white.svg';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

import InfoIcon from './images/info.svg';
import WarningIcon from './images/warning.svg';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      [theme.breakpoints.up(Layout.laptop)]: {
        '& .MuiButton-outlinedPrimary': {
          color: `#fff !important`,
        },
        minHeight: '620px',
      },
    },
    title: {
      color: _var(CSS_VARIABLES.colorPrimary100),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginBottom: '27px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#F7F9FC',
        fontWeight: 600,
      },
    },
    hintButton: {
      width: '25px',
      height: '25px',
      position: 'absolute',
      marginLeft: '10px',
      cursor: 'pointer',
      background: `url(${ContectSupportIcon}) no-repeat left center`,
      right: '0px',
      top: '5px',
    },
    backButton: {
      position: 'absolute',
      left: '32px',
      top: '36px',
      [theme.breakpoints.down(Layout.tablet)]: {
        left: '0px',
        top: '0px',
      },
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
    infoField: {
      width: '100%',
      height: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '4px 20px',
    },
    generalInfoField: {
      width: '100%',
      height: '32px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '4px 20px',
    },
    fieldTitle: {
      color: 'rgba(255, 255, 255, 0.60)',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.3px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    value: {
      color: 'rgba(255, 255, 255, 0.60)',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.3px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    bold: {
      color: '#fff',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '170%',
      letterSpacing: '0.6px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    infoBlock: {},
    typeOfTransaction: {
      background: '#1E1D36',
      width: '100%',
      height: '60px',
      borderRadius: '12px 12px 0px 0px',
      padding: '10px 0px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
        background: '#412D68',
      },
    },
    generalInfoBlock: {
      margin: '1px 0px',
      background: '#1E1D36',
      width: '100%',
      height: '185px',
      padding: '20px 0px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
        background: '#412D68',
      },
    },
    total: {
      background: '#1E1D36',
      width: '100%',
      height: '60px',
      borderRadius: '0px 0px 12px 12px',
      padding: '10px 0px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
        background: '#412D68',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '170%',
        letterSpacing: '0.6px',
      },
    },
    paymentMethod: {
      height: '60px',
      marginTop: '10px',
      [theme.breakpoints.up(Layout.laptop)]: {
        '& .wallet-root': {
          background: '#412D68',
        },
        '& .card-root': {
          background: '#412D68',
        },
      },
    },
    messageBlock: {
      borderRadius: '12px',
      padding: '15px 20px 15px 40px',
      position: 'relative',
      marginTop: '10px',
    },
    info: {
      border: '1px solid #7543FF',
      background: '#1E1D36',
      '& $icon': {
        background: `url(${InfoIcon}) no-repeat left center`,
        left: '11px',
        top: '15px',
        width: '22px',
        height: '22px',
      },
      [theme.breakpoints.up(Layout.laptop)]: {
        borderRadius: '12px',
        background: '#372753',
        width: '420px',
      },
    },
    warning: {
      border: '1px solid #B42626',
      background: '#231010',
      '& $icon': {
        background: `url(${WarningIcon}) no-repeat left center`,
        left: '13px',
        top: '15px',
        width: '20px',
        height: '20px',
      },
      [theme.breakpoints.up(Layout.laptop)]: {
        background: 'rgba(209, 47, 47, 0.10)',
        border: `1px solid #D12F2F`,
        color: '#fff',
        borderRadius: '12px',
        width: '420px',
        marginBottom: '10px',
      },
    },
    message: {
      color: _var(CSS_VARIABLES.colorPrimary300),
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '170%',
      letterSpacing: '0.3px',
    },
    icon: {
      position: 'absolute',
    },
    footnote: {
      marginTop: '10px',
      color: '#8E93A4',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '170%',
      letterSpacing: '0.6px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
        display: 'none',
      },
    },
    fee: {
      marginTop: '15px',
      [theme.breakpoints.up(Layout.laptop)]: {
        marginTop: '0px',
      },
    },
    submit: {
      marginBottom: '20px',
      width: '100%',
    },
  });
}
