import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Box, Button, Drawer, Modal, Theme, useMediaQuery } from '@mui/material';
import { CloseDrawerButton } from '@shared/components/new-design/close-drawer-button/close-drawer-button';
import { DocumentsTitle } from '@shared/enums/documents-title.enum';
import { Layout } from '@shared/utils/layout';

import { styles } from './document-dialog.styles';

export interface DocumentDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  type: DocumentsTitle;
  url: string;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 535,
  background: '#291945',
  border: '1px solid #473763',
  borderRadius: '10px',
  '&:focus-visible': {
    outline: 'none',
  },
};

const DocumentDialogComponent: FC<DocumentDialogProps> = ({
  classes,
  onClose,
  isOpen,
  title,
  url,
  type,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  const getType = (documentType: DocumentsTitle) => {
    switch (documentType) {
      case DocumentsTitle.DEPOSIT:
        return t`DEPOSITING`;
      case DocumentsTitle.PURCHASE:
        return t`PURCHASE`;
      case DocumentsTitle.SELL:
        return t`SALES of GOLD`;
      case DocumentsTitle.TRANSFER:
        return t`TRANSFER`;
      case DocumentsTitle.WITHDRAW:
        return t`WITHDRAWAL of FIAT TOKENS`;
      default:
        return '';
    }
  };

  const documentsComponent = (
    <div className={classes.viewBlock}>
      <div className={classes.title}>{title}</div>
      <div className={classes.type}>{getType(type)}</div>
      <div className={classes.iframeBlock}>
        <iframe className={classes.iframe} src={url}></iframe>
      </div>
      <div className={classes.closeButton}>
        <Button variant="contained" disabled={false} onClick={onClose}>
          {t`OK`}
        </Button>
      </div>
    </div>
  );

  let content = <></>;

  if (isMobile) {
    content = (
      <Drawer anchor={'bottom'} open={isOpen} className={classes.drawer} onClose={onClose}>
        <div className={classes.drawerContent}>{documentsComponent}</div>
        <CloseDrawerButton onClick={onClose} />
      </Drawer>
    );
  } else {
    content = (
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{documentsComponent}</Box>
      </Modal>
    );
  }
  return <div className={classes.root}>{content}</div>;
};

export default appWithStyles(styles)(DocumentDialogComponent);
