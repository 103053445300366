import React, { useEffect, useMemo, useState, KeyboardEvent } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import FiatLimitsInfoDialog from '@modules/new-private/orders/purchase-and-sell/fiat-limits-info-dialog/fiat-limits-info-dialog';
import LimitsInfoDialog from '@modules/new-private/orders/purchase-and-sell/limits-info-dialog/limits-info-dialog';
import SwitchButtonIcon from '@modules/new-private/orders/purchase-and-sell/purchase-and-sell-form/img/switch-icon.svg';
import { PurchaseViewModel } from '@modules/new-private/orders/purchase-and-sell/purchase.vm';
import { SellFiatTokenViewModel } from '@modules/new-private/orders/purchase-and-sell/sell-fiat-token.vm';
import { SellViewModel } from '@modules/new-private/orders/purchase-and-sell/sell.vm';
import { TextFieldProps, Theme, useMediaQuery } from '@mui/material';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { InputCurrency } from '@shared/components/new-design/input-currency-text';
import { SelectPaymentMethod } from '@shared/components/new-design/select-payment-method';
import { SelectedPaymentMethod } from '@shared/components/new-design/select-payment-method/selected-payment-method.type';
import SubmitButton from '@shared/components/new-design/submit-button';
import { AddCardStripe } from '@shared/components/payments/stripe/add-card-stripe-component';
import AddCardWorldpay from '@shared/components/payments/worldpay/add-card-worldpay-component/add-card-worldpay-component';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { MultiMetalPaymentType } from '@shared/models/orders/payment-method';
import { ProductType } from '@shared/models/products/product-model';
import { Currency } from '@shared/models/wallets/currency';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { PaymentProviderEnum } from '@shared/types/payments/payment-provider.enum';
import { Layout } from '@shared/utils/layout';
import { amountToNumber, formatAmount } from '@shared/utils/metals';
import { nameOf } from '@shared/utils/nameof';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import LivePricingErrorDialog from '../live-pricing-error-dialog/live-pricing-error-dialog';
import NonWorkingHoursDialog from '../non-working-hours-dialog/non-working-hours-dialog';
import UpdateSpotPriceErrorDialog from '../update-spot-price-error-dialog/update-spot-price-error-dialog';
import {
  IPurchaseAndSellForm,
  PurchaseAndSellFieldsResolver,
  PurchaseAndSellFormFields,
} from './purchase-and-sell-form.validator';

import { styles } from './purchase-and-sell-form.styles';

export interface PurchaseFormProps extends AppWithStyles<typeof styles> {
  isLoading?: boolean;
  vm: PurchaseViewModel | SellViewModel | SellFiatTokenViewModel;
  onChangeCurrency: (currency: Currency) => void;
  onSubmit: (data: IPurchaseAndSellForm) => void;
  onOpenDocument: () => void;
}

const PurchaseFormComponent: React.FC<PurchaseFormProps> = appObserver(
  ({ classes, isLoading, vm, onChangeCurrency, onSubmit, onOpenDocument }) => {
    const {
      getValues,
      trigger,
      setValue,
      handleSubmit,
      formState: { isValid },
    } = useForm<PurchaseAndSellFormFields>({ resolver: PurchaseAndSellFieldsResolver });

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

    const [isOpenAddNewCard, setIsOpenAddNewCard] = useState(false);
    const [isOpenPaymentMethod, setIsOpenPaymentMethod] = useState(false);
    const [isOpenLimitsInfo, setIsOpenLimitsInfo] = useState(false);
    const [isOpenLivePricingError, setIsOpenLivePricingError] = useState(false);
    const [isOpenUpdateSpotPriceErrorDialog, setIsOpenUpdateSpotPriceErrorDialog] =
      useState<boolean>(false);
    const [isOpenNonWorkingHoursDialog, setIsOpenNonWorkingHoursDialog] = useState<boolean>(false);

    const navigate = useNavigate();

    const $vm = useMemo(() => vm, []);

    const loadData = () => {
      const localStorageFormData = $vm.loadState();
      if (
        localStorageFormData.smartCoinAmount ||
        localStorageFormData.paymentType ||
        localStorageFormData.paymentMethodId
      ) {
        return localStorageFormData;
      } else {
        return $vm.purchaseFormData || {};
      }
    };

    useEffect(() => {
      const { smartCoinAmount, paymentType, paymentMethodId } = loadData();
      if (smartCoinAmount || paymentType || paymentMethodId) {
        handleOnChangeSmartCoinAmount(`${smartCoinAmount}`);
        const paymentMethod = [...$vm.cards, ...$vm.wallets].find(
          (method) => method.asJson.id === paymentMethodId,
        );
        paymentMethod &&
          onSelectPaymentMethod({
            paymentMethod,
            paymentType: paymentType as MultiMetalPaymentType,
          });
        $vm.removeState();
      }
    }, []);

    const smartCoinAmountInputRef = React.useRef<(TextFieldProps & { focus: () => void }) | null>(
      null,
    );
    const fiatCurrencyAmountInputRef = React.useRef<
      (TextFieldProps & { focus: () => void }) | null
    >(null);

    const [displayedFiatCurrency, setDisplayedFiatCurrency] = useState<string>();
    const [smartCoinAmount, setSmartCoinAmount] = useState<string>('');
    const [fiatCurrencyAmount, setFiatCurrencyAmount] = useState<string>('');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<SelectedPaymentMethod>();
    useEffect(() => {
      if (getValues().smartCoinAmount) {
        handleOnChangeSmartCoinAmount(
          formatAmount(getValues().smartCoinAmount, $vm.smartCoinInputPrecision),
        );
      }
      const product = $vm.getProductByCurrency($vm.rate?.asJson?.currency || '');
      if (getValues().paymentType === MultiMetalPaymentType.deposit) {
        setDisplayedFiatCurrency(product?.asJson.name);
      } else {
        setDisplayedFiatCurrency(product?.asJson.baseCurrency);
      }
    }, [$vm.rate]);

    const addCardMutation = useMutation(
      (data: { paymentInfo: any; save: boolean }) =>
        $vm.handleAddNewCard(data.paymentInfo, data.save),
      {
        onSuccess: (paymentMethod) => {
          if (paymentMethod) {
            setIsOpenAddNewCard(false);
            setIsOpenPaymentMethod(true);
            $vm.showSuccess(t`Card successfully added.`);
          }
        },
        onError: () => {
          $vm.showError(t`Your card was declined.`);
        },
      },
    );

    const handleOnChangeSmartCoinAmount = (value: string) => {
      setSmartCoinAmount(value);
      setValue(nameOf<IPurchaseAndSellForm>('smartCoinAmount'), amountToNumber(value));
      const sourceValue = $vm.calculateFiatCurrencyAmount(value);
      setFiatCurrencyAmount(sourceValue);
      setValue(nameOf<IPurchaseAndSellForm>('fiatCurrencyAmount'), amountToNumber(sourceValue));
      trigger(nameOf<IPurchaseAndSellForm>('smartCoinAmount'));
      trigger(nameOf<IPurchaseAndSellForm>('fiatCurrencyAmount'));
      selectedPaymentMethod &&
        $vm.checkLimits(
          selectedPaymentMethod?.paymentType,
          amountToNumber(sourceValue),
          displayedFiatCurrency as string,
        );
    };

    const handleOnChangeFiatCurrencyAmount = (value: string) => {
      setFiatCurrencyAmount(value);
      setValue(nameOf<IPurchaseAndSellForm>('fiatCurrencyAmount'), amountToNumber(value));
      const targetValue = $vm.calculateSmartCoinAmount(value);
      setSmartCoinAmount(targetValue);
      setValue(nameOf<IPurchaseAndSellForm>('smartCoinAmount'), amountToNumber(targetValue));
      trigger(nameOf<IPurchaseAndSellForm>('smartCoinAmount'));
      trigger(nameOf<IPurchaseAndSellForm>('fiatCurrencyAmount'));
    };

    const onSubmitFrom = (data: IPurchaseAndSellForm) => {
      onSubmit(data);
    };

    const onSelectPaymentMethod = (paymentMethod: SelectedPaymentMethod) => {
      setSelectedPaymentMethod(paymentMethod);
      setValue(
        nameOf<IPurchaseAndSellForm>('paymentMethodId'),
        paymentMethod.paymentMethod.asJson.id,
      );
      setValue(nameOf<IPurchaseAndSellForm>('paymentType'), paymentMethod.paymentType);
      trigger(nameOf<IPurchaseAndSellForm>('paymentMethodId'));
      if (paymentMethod.paymentType === MultiMetalPaymentType.deposit) {
        $vm.checkLimits(
          paymentMethod.paymentType,
          getValues().fiatCurrencyAmount,
          (paymentMethod.paymentMethod as WalletsListModel).asJson.currency as string,
        );

        setDisplayedFiatCurrency((paymentMethod.paymentMethod as WalletsListModel).asJson.currency);
        onChangeCurrency(
          (paymentMethod.paymentMethod as WalletsListModel).asJson.currency as Currency,
        );
      } else {
        const product = $vm.getProductByCurrency($vm.rate?.asJson?.currency || '');
        if (!product) return;
        $vm.checkLimits(
          paymentMethod.paymentType,
          getValues().fiatCurrencyAmount,
          product.asJson.baseCurrency as string,
        );
        setDisplayedFiatCurrency(product.asJson.baseCurrency);
        onChangeCurrency(product?.asJson.name as Currency);
      }
      setIsOpenPaymentMethod(false);
    };

    const handleCloseAddNewCard = () => {
      setIsOpenAddNewCard(false);
      setIsOpenPaymentMethod(true);
    };

    const toggleIsOpenPaymentMethod = (isOpen: boolean) => {
      if ($vm.isActiveMaintenanceMode || $vm.rate?.asJson?.isEmergencyEnabled) {
        setIsOpenPaymentMethod(false);
        return;
      }
      setIsOpenPaymentMethod(isOpen);
    };

    const navigateToDashboard = () => {
      navigate(ROUTES.mobilePrivate.dashboard);
    };

    const handleOpenInfoMessage = () => {
      if ($vm.rate && !$vm.rate.asJson?.isSpot) {
        setIsOpenNonWorkingHoursDialog(true);
      } else if ($vm.requestRatesError) {
        setIsOpenUpdateSpotPriceErrorDialog(true);
      }
    };

    const handleOpenLivePricingMessage = () => {
      setIsOpenLivePricingError(true);
    };

    const handleMaintenancePage = () => {
      isMobile
        ? navigate(ROUTES.mobilePrivate.maintenancePage)
        : navigate(ROUTES.desktopPrivate.maintenancePage);
    };

    const isValidSmartCoinSellAmountToBalance = (balance: number, amount: number = 0) => {
      if (amount === 0) return true;
      return amount <= balance;
    };

    let paymentComponent = <div></div>;
    if ($vm.activePaymentSystem() === PaymentProviderEnum.STRIPE) {
      paymentComponent = (
        <AddCardStripe
          showSave
          isOpen={isOpenAddNewCard}
          loading={addCardMutation.isLoading}
          onSave={(paymentInfo, save) => addCardMutation.mutate({ paymentInfo, save })}
          onCancel={handleCloseAddNewCard}
          confirmButtonLabel={t`Proceed`}
        />
      );
    } else if ($vm.activePaymentSystem() === PaymentProviderEnum.WORLDPAY) {
      paymentComponent = (
        <AddCardWorldpay
          showSave
          isOpen={isOpenAddNewCard}
          error={addCardMutation.isError}
          loading={addCardMutation.isLoading}
          onSave={(paymentInfo, save) => addCardMutation.mutate({ paymentInfo, save })}
          onCancel={handleCloseAddNewCard}
          confirmButtonLabel={t`Continue`}
        />
      );
    }

    const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
      if (
        e.code === 'Enter' &&
        isValid &&
        $vm.isValidOrderByBalance(getValues(), selectedPaymentMethod?.paymentMethod)
      ) {
        handleSubmit(onSubmitFrom)();
      }
    };

    const livePricingErrorBlock = (
      <div className={classes.availableBlock}>
        <span className={classes.available}>{t`Live pricing error.`}</span>
        <span
          className={classes.seeDetails}
          onClick={handleOpenLivePricingMessage}
        >{t`See details`}</span>
      </div>
    );

    const nonWorkingHourseOrRequestRatesBlock = (
      <div className={classes.availableBlock}>
        <span className={classes.available}>{t`Gold Market Closed:`}</span>{' '}
        <span
          className={classes.seeDetails}
          onClick={handleOpenInfoMessage}
        >{t`See trading hours`}</span>
      </div>
    );

    const maintenanceModeBlock = (
      <div className={classes.availableBlock}>
        <span className={classes.available}>{t`System is under maintenance.`}</span>
        <span className={classes.seeDetails} onClick={handleMaintenancePage}>{t`See details`}</span>
      </div>
    );

    const limitsInfo = (
      <div className={classes.limitInfoBlock}>
        <span className={classes.limitInfo}>
          {$vm.isSellOut
            ? t`System limits for sell out transactions.`
            : t`System limits for purchase transactions.`}
        </span>
        <span
          className={classes.seeDetails}
          onClick={() => setIsOpenLimitsInfo(true)}
        >{t`See details`}</span>
      </div>
    );

    const limitsError = (
      <div className={classes.limitInfoBlock}>
        <span className={classes.limitInfoError}>{$vm.limitError}.</span>
        <span
          className={classes.seeDetails}
          onClick={() => setIsOpenLimitsInfo(true)}
        >{t`See details`}</span>
      </div>
    );

    const getInfoComponent = () => {
      let infoComponent = <></>;
      switch (true) {
        case $vm.isActiveMaintenanceMode:
          infoComponent = maintenanceModeBlock;
          break;
        case !$vm.isFiatToken && $vm.rate && $vm.rate.asJson && !$vm.rate.asJson.isSpot:
        case $vm.requestRatesError:
          infoComponent = nonWorkingHourseOrRequestRatesBlock;
          break;
        case $vm.rate && $vm.rate.asJson && $vm.rate.asJson.isEmergencyEnabled:
          infoComponent = livePricingErrorBlock;
          break;
        case !selectedPaymentMethod:
          infoComponent = limitsInfo;
          break;
        case Boolean($vm.limitError):
          infoComponent = limitsError;
          break;
        default:
          break;
      }
      return infoComponent;
    };

    const activeWallet = $vm.getActiveWallet();

    return (
      <>
        <form
          onSubmit={handleSubmit(onSubmitFrom)}
          onKeyDown={handleKeyPress}
          className={classes.root}
        >
          <div>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToDashboard} />
            </div>
            <div className={classes.title}>
              {$vm.screenName} {$vm.smartCoinNameTitle}
            </div>
            <div
              className={classes.hintButton}
              onClick={() => onOpenDocument && onOpenDocument()}
            ></div>
            <div className={classes.rate}>
              <span>{$vm.rateText}</span>
              <span
                className={classNames({
                  [classes.available as string]: $vm.rate?.asJson?.isEmergencyEnabled,
                })}
              >
                {$vm.rateTextSecondPart}
              </span>
            </div>
            <div className={classes.fields}>
              <div className={classes.amountBlock}>
                <div className={classes.switchButton}>
                  <img src={String(SwitchButtonIcon)} />
                </div>
                <div className={classes.field}>
                  {$vm.isPurchase ? (
                    <InputCurrency
                      resetValidation={() => {}}
                      digitsCountAfterDot={$vm.smartCoinInputPrecision}
                      autoFocus={true}
                      inputRef={smartCoinAmountInputRef}
                      currency={$vm.smartCoinName}
                      value={smartCoinAmount}
                      disabled={$vm.isActiveMaintenanceMode || $vm.rate?.asJson?.isEmergencyEnabled}
                      onChangeValue={handleOnChangeSmartCoinAmount}
                      error={$vm.smartCoinAmountError}
                      key={'smartCoinAmount'}
                    />
                  ) : (
                    <InputCurrency
                      resetValidation={() => {}}
                      digitsCountAfterDot={$vm.smartCoinInputPrecision}
                      autoFocus={true}
                      inputRef={smartCoinAmountInputRef}
                      currency={$vm.smartCoinName}
                      disabled={$vm.isActiveMaintenanceMode || $vm.rate?.asJson?.isEmergencyEnabled}
                      value={smartCoinAmount}
                      text={
                        activeWallet
                          ? `${minDigitsAfterDot(
                              amountPipe(activeWallet.asJson.balance, $vm.smartCoinInputPrecision),
                            )} ${activeWallet.asJson.currency}`
                          : ''
                      }
                      onChangeValue={handleOnChangeSmartCoinAmount}
                      error={
                        $vm.smartCoinAmountError ||
                        !isValidSmartCoinSellAmountToBalance(
                          activeWallet?.asJson.balance || 0,
                          getValues().smartCoinAmount,
                        )
                      }
                      key={'smartCoinAmount'}
                    />
                  )}
                </div>
                <div className={classes.field}>
                  <InputCurrency
                    resetValidation={() => {}}
                    inputRef={fiatCurrencyAmountInputRef}
                    value={fiatCurrencyAmount}
                    disabled={true}
                    currency={displayedFiatCurrency as string}
                    onChangeValue={handleOnChangeFiatCurrencyAmount}
                    error={
                      $vm.fiatCurrencyAmountError ||
                      ($vm.isPurchase &&
                        selectedPaymentMethod &&
                        !$vm.isValidOrderByBalance(
                          getValues() as IPurchaseAndSellForm,
                          selectedPaymentMethod?.paymentMethod,
                        ))
                    }
                    key={'fiatCurrencyAmount'}
                    errorText={
                      $vm.hasMinLimitError()
                        ? `${t`less than`} ${minDigitsAfterDot(
                            amountPipe(
                              $vm.getCurrentLimits(selectedPaymentMethod?.paymentType)
                                ?.lowerLimit || 0.01,
                            ),
                            2,
                          )}`
                        : ''
                    }
                  />
                </div>
              </div>
              <SelectPaymentMethod
                wallets={
                  $vm.isFiatToken
                    ? []
                    : $vm.wallets.filter(
                        (wallet) =>
                          $vm.getProductTypeByCurrency(wallet.asJson.currency) ===
                          (ProductType.FIAT_COIN as string),
                      )
                }
                onAddNewCard={() => setIsOpenAddNewCard(true)}
                cards={$vm.cards}
                isPurchase={$vm.isPurchase}
                isHiddenCards={!$vm.isAvailabeCards}
                description={$vm.paymentMethodDescription}
                emptyStateText={
                  $vm.isFiatToken
                    ? t`Please add card to use it as a destination to withdraw money`
                    : ''
                }
                isOpenPaymentMethod={isOpenPaymentMethod}
                toggleIsOpenPaymentMethod={toggleIsOpenPaymentMethod}
                defaultPaymentMethod={selectedPaymentMethod}
                onSelectPaymentMethod={onSelectPaymentMethod}
              />
            </div>
          </div>
          <div>
            {getInfoComponent()}
            <div className={classes.submit}>
              <SubmitButton
                label={t`Continue`}
                disabled={
                  !isValid ||
                  $vm.isActiveMaintenanceMode ||
                  $vm.downloadingLimitsError ||
                  !$vm.isValidRates() ||
                  Boolean($vm.limitError) ||
                  !$vm.isValidOrderByBalance(
                    getValues() as IPurchaseAndSellForm,
                    selectedPaymentMethod?.paymentMethod,
                  )
                }
                isLoading={isLoading}
                onSubmit={handleSubmit(onSubmitFrom)}
              />
            </div>
          </div>
        </form>
        {paymentComponent}
        {$vm.isFiatToken ? (
          <FiatLimitsInfoDialog
            isOpen={isOpenLimitsInfo}
            onClose={() => setIsOpenLimitsInfo(false)}
            vm={$vm}
          />
        ) : (
          <LimitsInfoDialog
            isOpen={isOpenLimitsInfo}
            onClose={() => setIsOpenLimitsInfo(false)}
            vm={$vm}
          />
        )}
        <NonWorkingHoursDialog
          isOpen={isOpenNonWorkingHoursDialog}
          onClose={() => setIsOpenNonWorkingHoursDialog(false)}
        />
        <UpdateSpotPriceErrorDialog
          isOpen={isOpenUpdateSpotPriceErrorDialog}
          onClose={() => setIsOpenUpdateSpotPriceErrorDialog(false)}
        />
        <LivePricingErrorDialog
          isOpen={isOpenLivePricingError}
          onClose={() => setIsOpenLivePricingError(false)}
        />
      </>
    );
  },
);

export default appWithStyles(styles)(PurchaseFormComponent);
