import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { IsNotEmpty, IsNumber, IsString, Min } from 'class-validator';

export interface IPurchaseAndSellForm {
  smartCoinAmount: number;
  fiatCurrencyAmount: number;
  paymentMethodId: string;
  paymentType: string;
}

export class PurchaseAndSellFormFields implements IPurchaseAndSellForm {
  @IsNumber()
  @Min(0.00000001)
  smartCoinAmount: number;

  @IsNumber()
  @Min(0.01)
  fiatCurrencyAmount: number;

  @IsString()
  @IsNotEmpty()
  paymentMethodId: string;

  paymentType: string;
}

export const PurchaseAndSellFieldsResolver = classValidatorResolver(PurchaseAndSellFormFields);
