import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import PurchaseAndSellBase from '../purchase-and-sell-base/purchase-and-sell-base';

import { styles } from './purchase-and-sell-mobile.styles';

export interface PurchaseAndSellMobileProps extends AppWithStyles<typeof styles> {}

const PurchaseAndSellMobileComponent: FC<PurchaseAndSellMobileProps> = ({ classes }) => {
  return (
    <div className={classes.root}>
      <PurchaseAndSellBase />
    </div>
  );
};
export default appWithStyles(styles)(PurchaseAndSellMobileComponent);
