import { Dispatch } from 'react';

import { t } from '@lingui/macro';
import { IPurchaseAndSellForm } from '@modules/new-private/orders/purchase-and-sell/purchase-and-sell-form/purchase-and-sell-form.validator';
import { NavigateFunction } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { DocumentsTitle } from '@shared/enums/documents-title.enum';
import { TransactionMessageType } from '@shared/enums/transaction-message-type';
import { TransactionOperationType } from '@shared/enums/transaction-operation-type.enum';
import { CreditCardListModel } from '@shared/models/credit-card/list-model';
import { CreateOrderDto, CreateOrderModel } from '@shared/models/orders/create-model';
import { MultiMetalPaymentType } from '@shared/models/orders/payment-method';
import { Currency } from '@shared/models/wallets/currency';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { sanitizedAmount } from '@shared/utils/metals';
import { BigNumber } from 'bignumber.js';

import { PurchaseAndSellViewModel } from './purchase-and-sell.vm';

export class SellFiatTokenViewModel extends PurchaseAndSellViewModel {
  private savedOrder: CreateOrderDto;

  constructor(data: {
    smartCoinName: string;
    setIsOpenVerificationDialog: Dispatch<boolean>;
    navigateFn: NavigateFunction;
  }) {
    super(data);
    this.isBuy = false;
  }

  get screenName() {
    return t`Withdraw`;
  }

  get round() {
    return BigNumber.ROUND_FLOOR;
  }

  get transactionTypeLabel() {
    return t`Withdrawal`;
  }

  get documentTitle() {
    return DocumentsTitle.WITHDRAW;
  }

  get confirmScreenTitle() {
    return t`Confirm Withdrawal`;
  }

  get rateText() {
    return `1.00 ${this.smartCoinName} = 1.00 ${this.getMetalNameBySmartCoinName(
      this.smartCoinName,
    )}`;
  }

  get rateTextSecondPart() {
    return '';
  }

  get smartCoinInputPrecision() {
    return 2;
  }

  get noteMessage() {
    return t`Note that your bank may charge own fees for converting and crediting money into the card account`;
  }

  get footNoteFromConfirmationScreen() {
    return ``;
  }

  get confirmScreenRateTitle() {
    return t`Withdrawal Rate`;
  }

  get confirmScreenRateValue() {
    return `1 ${this.smartCoinName} = 1.00 ${
      this.getProductByCurrency(this.smartCoinName)?.asJson.baseCurrency
    }`;
  }

  get isExpiredRate() {
    return false;
  }

  get isDisableConfirmScreenSubmitButton() {
    return false;
  }

  get walletId() {
    return this.wallets.find((w) => w.asJson.currency === this.smartCoinName)?.asJson.id || '';
  }

  isValidRates(): boolean {
    return true;
  }

  get receiptCurrency() {
    return this.getProductByCurrency(this.smartCoinName)?.asJson.baseCurrency || '';
  }

  get paymentMethodDescription() {
    return t`Select the payment card to which you want to withdraw money`;
  }

  isValidOrderByBalance = (
    formData: IPurchaseAndSellForm,
    paymentMethod: WalletsListModel | CreditCardListModel | undefined,
  ) => {
    if (!paymentMethod) return false;
    const wallet = this.wallets.find((w) => w.asJson.currency === this.smartCoinName);
    if (!wallet || wallet.asJson.balance < formData.smartCoinAmount) {
      return false;
    }
    return true;
  };

  getCurrentLimits(paymentType: MultiMetalPaymentType | undefined) {
    if (!this.limits?.asJson?.creditCards) {
      return null;
    }
    return this.limits?.asJson?.creditCards;
  }

  isInsufficient(amount: number, count: number, fee: number = 0) {
    return false;
  }

  async createSmartCoinOrder(
    formData: IPurchaseAndSellForm,
    smartCoin: string,
    fiatCurrency: string,
  ) {
    this.savedOrder = {
      paymentMethod: formData.paymentType as MultiMetalPaymentType,
      paymentMethodId: formData.paymentMethodId,
      quantity: formData.smartCoinAmount,
      fiatCurrency,
      smartCoin,
    };
    let transactionFee = 0;
    if (formData.paymentType === MultiMetalPaymentType.card) {
      const fee = await this.walletsService.getTransactionFee(
        formData.paymentMethodId,
        TransactionOperationType.WITHDRAWAL,
        formData.fiatCurrencyAmount,
      );
      transactionFee = parseFloat(BigNumber(fee).toFixed(2, BigNumber.ROUND_CEIL));
    }
    this.createdOrder = new CreateOrderModel({
      id: '',
      quantity: formData.smartCoinAmount,
      smartCoin: smartCoin,
      transactionFee: transactionFee,
      totalValue: 0,
      fiatCurrency: fiatCurrency,
      isCard: formData.paymentType === MultiMetalPaymentType.card,
      paymentMethodId: formData.paymentMethodId,
    });
    this.selectPaymentMethod(formData);
    if (!this.selectedPaymentMethod) throw 'Payment method not found';
    this.purchaseFormData = formData;
    await this.downloadRates(this.activeCurrency as Currency);
  }

  async confirmSmartCoinOrder(orderId: string) {
    try {
      const response = await this.walletsService.sellFiatTokens(
        this.walletId,
        this.savedOrder.paymentMethodId,
        sanitizedAmount(this.savedOrder.quantity),
      );
      if (!response.asJson.isSucceeded) {
        this.notificationService.showError(t`Something went wrong, please try again`);
      } else {
        this.showSuccessfulTransactionMessage(
          this.savedOrder.quantity,
          this.savedOrder.smartCoin,
          response.asJson.transactionId,
        );
      }
      this.navigateFn(ROUTES.mobilePrivate.dashboard);
      this.handleSmartCoinOrderConfirmSuccess({ orderId }, { id: '' });
      return { id: '' };
    } catch (ex) {
      this.handleSmartCoinOrderError(ex);
      return { id: '' };
    }
  }

  showSuccessfulTransactionMessage(amount: number, currency: string, transactionId: string) {
    this.showTransactionMessage(
      amount,
      currency,
      'successful',
      TransactionMessageType.SELL_FIAT_TOKEN,
      transactionId,
    );
  }

  showInProgressTransactionMessage(amount: number, currency: string) {
    this.showTransactionMessage(
      amount,
      currency,
      'inProgress',
      TransactionMessageType.SELL_FIAT_TOKEN,
    );
  }

  showCanceledTransactionMessage(amount: number, currency: string) {
    this.showTransactionMessage(
      amount,
      currency,
      'canceled',
      TransactionMessageType.SELL_FIAT_TOKEN,
    );
  }
}
