import React, { useMemo, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Avatar } from '@modules/private/onboarding/forms/avatars/components/avatar';
import { EmptyAvatar } from '@modules/private/onboarding/forms/avatars/components/empty-avatar';
import { ImageList, Theme, useMediaQuery } from '@mui/material';
import { Loading } from '@shared/components/loading';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { Layout } from '@shared/utils/layout';
import { useMutation, useQuery } from 'react-query';

import { ChangeAvatarViewModel } from './change-avatar.vm';

import { styles } from './change-avatar.styles';

export interface ChangeAvatarProps extends AppWithStyles<typeof styles> {}

const ChangeAvatarComponent: React.FC<ChangeAvatarProps> = appObserver(({ classes }) => {
  const [selectedAvatar, setSelectedAvatar] = useState<string | null>('not-selected');
  const [currentAvatar, setCurrentAvatar] = useState<string | null>('not-selected');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  const $vm = useMemo(() => new ChangeAvatarViewModel(), []);

  const loadDataQuery = useQuery(['loadData'], () => $vm.loadData(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retryOnMount: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    onSuccess: () => {
      const userAvatar =
        $vm.avatars.find((avatar) => avatar.url === $vm.myDetails.asJson.avatarUrl)?.id || null;
      setCurrentAvatar(userAvatar);
      setSelectedAvatar(userAvatar);
    },
  });

  const saveAvatarMutation = useMutation(
    (data: { avatarId: string | null }) => $vm.saveAvatar(data.avatarId),
    {
      onSuccess: () => {
        navigate(ROUTES.mobilePrivate.settings.security.root);
        navigateToPersonalInfo();
      },
    },
  );

  const handleSave = () => {
    saveAvatarMutation.mutate({ avatarId: selectedAvatar });
  };

  const navigate = useNavigate();

  const navigateToPersonalInfo = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.settings.personalInfo)
      : navigate(ROUTES.desktopPrivate.settings.personalInfo);
  };

  const onSelectAvatar = (id: string | null) => {
    setSelectedAvatar(id);
  };

  if (loadDataQuery.isFetching) {
    return (
      <div className={classes.root}>
        <Loading size={70} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.changeAvatarBlock}>
        <div className={classes.changeAvatar}>
          <div className={classes.titleAndIconsBlock}>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToPersonalInfo} />
            </div>
            <div className={classes.title}>{t`Choose Your Avatar`}</div>
            <div className={classes.imageList}>
              <ImageList
                sx={{ width: 355, height: '100%', paddingTop: '24px' }}
                cols={3}
                rowHeight={139}
              >
                <EmptyAvatar
                  nameAbbreviation={$vm.emptyAvatar.nameAbbreviation}
                  color={$vm.emptyAvatar.color}
                  isActive={selectedAvatar === null}
                  onSelect={onSelectAvatar}
                />
                {$vm.avatars.map((item, index) => {
                  return (
                    <Avatar
                      id={item.id}
                      url={item.url}
                      label={`${t`Avatar`} ${index + 2}`}
                      isActive={selectedAvatar === item.id}
                      onSelect={onSelectAvatar}
                    />
                  );
                })}
              </ImageList>
            </div>
          </div>
          <div className={classes.saveButtonBlock}>
            <SubmitButton
              label={t`Save`}
              disabled={selectedAvatar === currentAvatar}
              isLoading={saveAvatarMutation.isLoading}
              onSubmit={handleSave}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default appWithStyles(styles)(ChangeAvatarComponent);
